<template>
    <v-row>
        <v-col cols="12">
            <v-btn class="ma-1" large solo depressed color="secondary" @click="upload">
                <v-icon class="mr-2">cloud_upload</v-icon>
                <span class="subtitle-2">Subir material (PDF)</span>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <DashCard title="Material Subido" title-icon="list" body-class="pa-0">
                <v-data-table dense :items="index" :headers="grupos_header" :mobile-breakpoint="0" :items-per-page="15">
                    <template v-slot:item.nombre="{ item }">
                        <!-- <a> -->
                        <!-- <a @click="show(item)"> -->
                        <v-icon left>picture_as_pdf</v-icon>{{ item.nombre }}
                        <!-- </a> -->
                    </template>
                    <template v-slot:item.creacion="{ item }">
                        {{ item.creacion | moment('L HH:mm') }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn text fab small color="primary" depressed v-on="on">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                <!-- Descomentar cuando este disponible el visualizador de pdf -->
                                <!-- <v-list-item @click="show(item)">
                                    <v-list-item-icon>
                                        <v-icon>visibility</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Vista previa</v-list-item-title>
                                </v-list-item> -->
                                <v-list-item @click="descargar(item)">
                                    <v-list-item-icon>
                                        <v-icon>get_app</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Descargar</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="ask_delete(item)">
                                    <v-list-item-icon>
                                        <v-icon>close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Eliminar</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </DashCard>
        </v-col>
        <Message ref="message" />
        <CommonDialog ref="delete_dialog" @onClose="delete_item" />
        <DialogPreviewPresentacion ref="visor" />
        <DialogUploadPresentacion ref="upload" :data_file="data_file" @success="$parent.$parent.reload_dashboard()" />
    </v-row>
</template>

<script>
import download from 'downloadjs'
import Message from '@/shared/components/Message'
import DashCard from '@/shared/components/DashCard'
import CommonDialog from '@/shared/components/CommonDialog'
import DialogPreviewPresentacion from '@/educap/dialogs/DialogPreviewPresentacion'
import DialogUploadPresentacion from '@/educap/dialogs/DialogUploadPresentacion'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        DashCard,
        Message,
        CommonDialog,
        DialogPreviewPresentacion,
        DialogUploadPresentacion
    },
    data: () => ({
        index: [],
        grupos_header: [
            { value: "nombre", text: "Nombre" },
            { value: "creacion", text: "Subido el" },
            { value: "actions", text: "", sortable: false, width: "32px" }
        ],
        data_file: {
            titulo: 'Subir presentación',
            file_imput_label: 'Archivos pdf (*.pdf)',
            acepted_file_type: '.pdf',
            name: 'Nombre de la presentación',
            url_upload: 'edu/presentaciones/',
            message_success: 'La presentación ha sido subida correctamente'
        }
    }),
    methods: {
        show(item) {
            this.$refs.visor.show(item.archivo);
        },
        ask_delete(item) {
            this.$refs.delete_dialog.showDialog(messages.titles["question_eliminar_file"], messages.texts["question_eliminar_pres"], "yes_no", item);
        },
        delete_item(response, item) {
            if(response != 'yes') return;

            console.log(item);
            axiosHelper.del('edu/presentaciones/' + item.id + '/')
                .then(response => this.$parent.$parent.reload_dashboard())
                .catch(response => this.$refs.message.show(messages.titles["error_generico"], messages.texts["error_eliminar_pres"], "error"));
        },
        upload() {
            this.$refs.upload.show();
        },
        descargar(item) {
            download(item.archivo);
        }
    },
    mounted() {
        this.$parent.$parent.set_title("Repositorio de Material de Apoyo", "description");
        axiosHelper.get('edu/presentaciones/')
            .then(response => this.index = response.data);
    }
}
</script>